<template>
  <div class="accountset enterpriselist">
    <userheader menucurrent="2" />
    <div class="main-div">
      <div class="account-content wrap">
        <userleft currindex="5" />
        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="titleL">
                我的简历
                <em> 
                <router-link
                :to="{
                  path: '/user/accountset'
                }"
                class="content_button"
              
                >账户设置</router-link
              >
                </em>
              </h3>
              <el-tabs v-model="activeName" type="border-card" class="resumeTabs">
                <el-tab-pane label="基本信息" name="1">
                  <div class="basic clearfix">
                    <div class="infos">
                      <div class="clearfix">
                        <div class="title ">
                          <h2>{{ PerfectList.username }}</h2>
                        </div>
                        <div class="btn" v-if="false">
                          <div class="btn-edit" @click="basicMeaasgeShow">
                            <i class="resumeicon"></i>编辑
                          </div>
                        </div>
                      </div>
                      <ul class="clearfix" v-if="userinfo!=null">
                        <li>
                          <i></i>
                          {{ PerfectList.sex }}/ {{ userinfo.iDcardBirthdate }}/ {{ PerfectList.age }}岁
                        </li>
                        <li>
                          <i></i>
                          身份证号：{{ userinfo.iDcardNO }}
                        </li>
                        <li>
                          <i></i>
                          住址：{{ userinfo.iDcardAddress }}
                        </li>
                        <li>
                          <i></i>
                          签发机关： {{ userinfo.iDcardIssuingAuthority }}
                        </li>
                        <li>
                          <i></i>
                          有效期限： {{ userinfo.iDcardValidityperiodStart+'-'+ userinfo.iDcardValidityperiodEnd}}
                        </li>
                      </ul>
                    </div>
                    <div class="head" v-if="false">
                      <el-upload :action="uploadUrl" list-type="picture-card" name="file" :headers="myHeaders"
                        :show-file-list="false" :on-success="handleAvatarSuccess">
                        <img v-if="avatarIdPath" :src="avatarIdPath" class="avatar" />
                        <img v-else src="../../assets/img/timgs.jpg" class="avatar" />
                        <div slot="tip" class="el-upload__tip">
                          只能上传jpg/png图片，且不超过2M
                        </div>
                      </el-upload>
                    </div>
                  </div>
                  <div class="basic_edit edit" v-if="basicMeaasge">
                    <div class="title">基本信息</div>
                    <el-form label-width="80px" :model="basicEditForm" label-position="left" :rules="basicEditRules"
                      ref="basicEditRef">
                      <el-form-item label="姓名:" prop="username">
                        <span>{{basicEditForm.username}}</span>
                      </el-form-item>
                      <el-form-item label="年龄:" prop="age">
                        <el-input placeholder oninput="value=value.replace(/[^\d]/g,0)" class="controly" maxlength="2"
                          v-model="basicEditForm.age"></el-input>
                      </el-form-item>
                      <el-form-item label="性别:" prop="sex">
                        <span>{{basicEditForm.sex}}</span>
                      </el-form-item>
                      <el-form-item label="手机号码:">{{
                        PerfectList.phone
                      }}</el-form-item>
                      <el-form-item class="formL" label="最高学历" prop="education">
                        <el-select v-model="basicEditForm.education" placeholder="请选择" @change="getEducation"
                          class="controly">
                          <el-option v-for="item in educations" :key="item.value" :label="item.name" :value="item.code">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="工作年限:" prop="workExp">
                        <el-select v-model="basicEditForm.workExp" placeholder="请选择" class="controly"
                          @change="getworkExp">
                          <el-option v-for="(item, index) in workExpOptions" :key="index" :label="item.name"
                            :value="item.code"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item class="forma" label="现居住地" prop="address">
                        <el-input placeholder class="controly" v-model="basicEditForm.address.townName"></el-input>
                      </el-form-item>
                      <el-form-item class="forma" label="籍贯" prop="nativePlace">
                        <span>{{basicEditForm.nativePlace}}</span>
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" @click="basicEdit">完成</el-button>
                        <el-button @click="basicFields">取消</el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                  <div class="workexp infos intotitle">
                    <div style="margin-bottom:5px;">
                      <div class="title intotitle">
                        <h2>家庭社会关系</h2>
                      </div>
                    </div>
                    <div style="margin-top:10px;">
                      <!-- 用于循环数据 -->
                      <el-table :data="relationExperienceList" border style="width: 100%;margin-top:10px;">
                        <el-table-column prop="relation" label="称谓" align="center"></el-table-column>
                        <el-table-column prop="name" label="姓名" align="center"></el-table-column>
                        <el-table-column prop="age" label="年龄"></el-table-column>
                        <el-table-column prop="phone" label="联系电话" align="center"></el-table-column>
                        <el-table-column label="操作" align="center" width=180 fixed="right">
                          <template slot-scope="scope">
                            <div class="operation">
                              <el-button type="primary" class="infoBtn" size="small" plain
                                @click="showfamily_edit(scope.$index)">修改</el-button>
                              <el-button type="danger" size="small" slot="reference" plain
                                @click="showfamily_Delete(scope.row)">删除</el-button>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <!-- 添加家庭社会关系 -->
                    <div class="commonaddbtn" @click="showfamily_add">
                      <i class="resumeicon"></i>添加家庭社会关系
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
              <el-tabs v-model="activeName2" type="border-card" class="resumeTabs">
                <el-tab-pane label="学历/职称/奖项" name="1">
                  <div class="workexp infos">
                    <div class="clearfix">
                      <div class="title intotitle">
                        <h2>学历</h2>
                      </div>
                    </div>
                    <div style="margin-top:10px;">
                      <!-- 用于循环数据 -->
                      <el-table :data="schoolExperiences" border style="width: 100%;margin-top:10px;">
                        <el-table-column prop="schoolName" label="院校名称" align="center"></el-table-column>
                        <el-table-column prop="majorName" label="专业名称"></el-table-column>
                        <el-table-column label="毕业时间" align="center">
                          <template slot-scope="scope">
                            <div class="operation">
                              <span class="">{{dateFormat(scope.row.graduateStartDate)}}-</span>
                              <span class="">{{dateFormat(scope.row.graduateEndDate)}}</span>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column prop="policitalStatus" label="操作" width=180 align="center" fixed="right">
                          <template slot-scope="scope">
                            <div class="operation">

                              <el-button type="primary" class="infoBtn" size="small" plain
                                @click="showSchool_edit(scope.$index)">修改</el-button>
                              <el-button type="danger" size="small" slot="reference" plain
                                @click="showSchool_Delete(scope.row)">删除</el-button>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <!-- 添加学历 -->
                    <div class="commonaddbtn" @click="showSchool_add">
                      <i class="resumeicon"></i>添加学历
                    </div>
                  </div>
                  <div class="workexp infos">
                    <div class="clearfix">
                      <div class="title intotitle">
                        <h2>专业职称</h2>
                      </div>
                    </div>
                    <div style="margin-top:10px;" v-if="professioncertlistdata.length>0">
                      <span class="authentication" v-for="item in professioncertlistdata" v-bind:key="item.id">
                        <el-tag type="success" style="margin-right:10px;"> {{item.certType}}</el-tag>
                      </span>
                    </div>
                    <div style="margin-top:10px;" v-else>
                      无
                    </div>
                  </div>
                  <div class="workexp infos">
                    <div class="clearfix">
                      <div class="title intotitle">
                        <h2>奖项/奖励</h2>
                      </div>
                    </div>
                    <div style="margin-top:10px;">
                      <!-- 用于循环数据 -->
                      <el-table :data="rewardExperienceList" border style="width: 100%;margin-top:10px;">
                        <el-table-column prop="rewardName" label="奖项名称" align="center"></el-table-column>
                        <el-table-column prop="issueUnit" label="颁奖单位"></el-table-column>
                        <el-table-column label="获奖时间" align="center">
                          <template slot-scope="scope">
                            <div class="operation">
                              <span class="">{{dateFormat(scope.row.awardDate)}}</span>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column prop="policitalStatus" label="操作" width=180 align="center" fixed="right">
                          <template slot-scope="scope">
                            <div class="operation">
                              <el-button type="primary" class="infoBtn" size="small" plain
                                @click="showReward_edit(scope.$index)">修改</el-button>
                              <el-button type="danger" size="small" slot="reference" plain
                                @click="showReward_Delete(scope.row)">删除</el-button>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <!-- 添加学历 -->
                    <div class="commonaddbtn" @click="showReward_add">
                      <i class="resumeicon"></i>添加学历
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
              <el-tabs v-model="activeName2" type="border-card" class="resumeTabs">
                <el-tab-pane label="工作经历/成果" name="1">
                  <div class="workexp infos">
                    <div class="clearfix">
                      <div class="title intotitle">
                        <h2>工作经历</h2>
                      </div>
                    </div>
                    <div style="margin-top:10px;">
                      <!-- 用于循环数据 -->
                      <el-table :data="resumeperfectObj" border style="width: 100%;margin-top:10px;">
                        <el-table-column prop="companyName" label="单位名称" align="center"></el-table-column>
                        <el-table-column prop="positionName" label="职位名称"></el-table-column>
                        <el-table-column label="在职时间" align="center">
                          <template slot-scope="scope">
                            <div class="operation">
                              <span class="">{{dateFormat(scope.row.workStartDate)}}-</span>
                              <span class="">{{dateFormat(scope.row.workEndDate)}}</span>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column prop="policitalStatus" label="操作" width=180 align="center" fixed="right">
                          <template slot-scope="scope">
                            <div class="operation">

                              <el-button type="primary" class="infoBtn" size="small" plain
                                @click="showworkexp_edit(scope.$index)">修改</el-button>
                              <el-button type="danger" size="small" slot="reference" plain
                                @click="showworkexp_Delete(scope.row)">删除</el-button>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <!-- 添加学历 -->
                    <div class="commonaddbtn" @click="showworkexp_add">
                      <i class="resumeicon"></i>添加工作经历
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>

              <div class="submitbutton">
                <el-button type="primary" @click="onsubmit">提交审核</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <userfooter />

    <el-dialog :visible.sync="familydialogVisible" :close-on-click-modal="false" top="3vh" width="40%" title="家庭社会关系">
      <div class="workexp_edit edit">
        <el-form label-width="83px" :model="familySocialRelationsForm" label-position="left"
          ref="familySocialRelationsRef" :rules="familySocialRelationsRules">
          <el-form-item label="称谓：" prop="relation">
            <el-select v-model="familySocialRelationsForm.relation" placeholder="请选择称谓">
              <el-option v-for="item in appellationList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名: " prop="name">
            <el-input placeholder="" class="control" maxlength="10" v-model="familySocialRelationsForm.name"></el-input>
          </el-form-item>
          <el-form-item label="年龄: " prop="age">
            <el-input placeholder="" class="control" maxlength="2" v-model="familySocialRelationsForm.age"></el-input>
          </el-form-item>
          <el-form-item label="政治面貌：">
            <el-select v-model="familySocialRelationsForm.policitalStatus" placeholder="请选择政治面貌">
              <el-option v-for="item in politicalOutlookList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工作单位: " prop="workUnit">
            <el-input placeholder="" class="control" v-model="familySocialRelationsForm.workUnit">
            </el-input>
          </el-form-item>
          <el-form-item label="工作职位: " prop="workPost">
            <el-input placeholder="" class="control" v-model="familySocialRelationsForm.workPost">
            </el-input>
          </el-form-item>
          <el-form-item label="联系电话:" prop="phone">
            <el-input placeholder="" class="control" v-model="familySocialRelationsForm.phone"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="familySocialRelationsAdd('familySocialRelationsRef')">完成</el-button>
            <el-button @click="familyExperience">取消</el-button>
          </el-form-item>
        </el-form>
      </div>

    </el-dialog>
    <el-dialog :visible.sync="schooldialogVisible" :close-on-click-modal="false" top="3vh" width="40%" title="学历">
      <el-form label-width="83px" :model="schoolExperienceForm" label-position="left" ref="schoolExperienceRef"
        :rules="schoolExperienceRules">
        <el-form-item label="院校名称:" prop="schoolName">
          <el-input placeholder="" class="control" v-model="schoolExperienceForm.schoolName"></el-input>
        </el-form-item>
        <el-form-item label="专业名称:" prop="majorName">
          <el-input placeholder="" class="control" v-model="schoolExperienceForm.majorName"></el-input>
        </el-form-item>
        <el-form-item label="在校时间:" prop="graduateStartDate">
          <el-date-picker v-model="schoolExperienceForm.graduateStartDate" type="date" value-format="yyyy/MM/dd"
            placeholder="入学时间">
          </el-date-picker>
          至
          <el-date-picker v-model="schoolExperienceForm.graduateEndDate" type="date" value-format="yyyy/MM/dd"
            placeholder="毕业时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="在校经历:">
          <el-input type="textarea" v-model="schoolExperienceForm.schoolExperience" class="controlx" :rows="5">
          </el-input>
        </el-form-item>
        <el-form-item label="毕业证正本内容照片:">
          <el-upload :action="uploadUrl" list-type="picture-card" name="file" :headers="myHeaders"
            :show-file-list="false" :on-success="handlediplomaContentImgThumbPathSuccess">
            <img v-if="schoolExperienceForm.diplomaContentImgThumbPath"
              :src="schoolExperienceForm.diplomaContentImgThumbPath" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png图片，且不超过2M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="毕业证封面照片:">
          <el-upload :action="uploadUrl" list-type="picture-card" name="file" :headers="myHeaders"
            :show-file-list="false" :on-success="handlediplomaCoverImgPathSuccess">
            <img v-if="schoolExperienceForm.diplomaCoverImgThumbPath"
              :src="schoolExperienceForm.diplomaCoverImgThumbPath" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png图片，且不超过2M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="schoolExperienceAdd('schoolExperienceRef')">完成</el-button>
          <el-button @click="schoolExperienceRes2">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="workExperiencedialogVisible" :close-on-click-modal="false" top="3vh" width="40%"
      title="工作经历">
      <el-form label-width="83px" :model="workExperienceForm" label-position="left" ref="workExperienceRef"
        :rules="workExperienceRules">
        <el-form-item label="单位名称:" prop="companyName">
          <el-input placeholder="" class="control" v-model="workExperienceForm.companyName">
          </el-input>
        </el-form-item>
        <el-form-item label="职位名称:" prop="positionName">
          <el-input placeholder="" class="control" v-model="workExperienceForm.positionName">
          </el-input>
        </el-form-item>
        <el-form-item label="薪资:" prop="salary">
          <el-input placeholder="" maxlength="100000000" oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
            class="control" v-model="workExperienceForm.salary"></el-input>
        </el-form-item>
        <el-form-item label="在职时间:" prop="workEndDate">
          <el-date-picker v-model="workExperienceForm.workStartDate" type="date" value-format="yyyy/MM/dd"
            placeholder="选择日期">
          </el-date-picker>
          至
          <el-date-picker v-model="workExperienceForm.workEndDate" type="date" value-format="yyyy/MM/dd"
            placeholder="选择日期">
          </el-date-picker>
          <!-- <el-date-picker type="daterange" v-model="workExperienceForm.workEndDate" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" class="control"></el-date-picker> -->
        </el-form-item>
        <el-form-item label="工作内容:">
          <el-input type="textarea" v-model="workExperienceForm.jobContent" class="controlx" :rows="5"></el-input>
        </el-form-item>
        <el-form-item label="主要成果:">
          <el-input type="textarea" v-model="workExperienceForm.achievement" class="controlx" :rows="5"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="workExperienceEdit('workExperienceRef')">完成</el-button>
          <el-button @click="workExperienceRes1()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="rewarddialogVisible" top="5vh" width="40%" :close-on-click-modal="false" title="奖项/奖励">
      <el-form label-width="83px" :model="rewardExperienceForm" label-position="left" ref="rewardExperienceRef"
        :rules="RewardExperienceRules">
        <el-form-item label="奖项名称:" prop="rewardName">
          <el-input placeholder="" class="control" v-model="rewardExperienceForm.rewardName"></el-input>
        </el-form-item>
        <el-form-item label="颁发单位:" prop="issueUnit">
          <el-input placeholder="" class="control" v-model="rewardExperienceForm.issueUnit"></el-input>
        </el-form-item>
        <el-form-item label="获奖时间:" prop="awardDate">
          <el-date-picker type="date" v-model="rewardExperienceForm.awardDate" start-placeholder="日期"
            value-format="yyyy-MM-dd" class="control"></el-date-picker>
        </el-form-item>
        <el-form-item label="获奖证书图片:">
          <el-upload :action="uploadUrl" list-type="picture-card" name="file" :headers="myHeaders"
            :show-file-list="false" :on-success="handlerewardImguccess">
            <img v-if="rewardExperienceForm.rewardImgThumbPath!=''" :src="rewardExperienceForm.rewardImgThumbPath"
              class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png图片，且不超过2M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="rewardExperienceAdd('rewardExperienceRef')">完成</el-button>
          <el-button @click="rewardExperienceRes1(index)">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import Cookies from 'js-cookie';
import {getCookieByName} from '@/utils/helper';
var token =getCookieByName('access_token');
import userleft from '@/components/userleft.vue';
import { candidate } from '@/api/resumeview';
import { professioncertlist } from '@/api/user';
import { usergetcurruserinfo } from '@/api/user';
import {
  job,
  jobDelete,
  education,
  educationDelete,
  projectAdd,
  projectDelete,
  familyMember,
  familyMemberDelete,
  addorupdaterewardexperience,
  delrewardexperience,
} from '@/api/resumeperfect';
// import { businessDelete } from '@/api/store';
import { ExpectJobs, postResumes, ExpectJobs1 } from '@/api/resume';
import { cityjson } from '@/utils/cityjson.js';
export default {
  name: 'newResumeperfect',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    return {
      isEffective: false,
      familydialogVisible: false,
      schooldialogVisible: false,
      workExperiencedialogVisible: false,
      rewarddialogVisible: false,
      userinfo: null,
      professioncertlistdata: [],
      activeName: '1',
      activeName2: '1',
      //  工作经历
      workexpShow: false,
      // 学历
      schoolShow: false,
      // 自我分享
      SelfEvaluationShow: false,
      // 项目经验
      ProjectShow: false,
      // 基本信息
      basicMeaasge: false,
      // 基本信息表单
      form: {
        region: '',
      },
      // 获取简历修改页面的数据
      PerfectList: {},
      // 获取头像的路径
      avatarIdPath: '',
      // 获取头像的id
      avatarId: '',
      // 求职意向隐藏与显示
      jobobjectiveShow: false,
      // 求职意向
      jobobjective_editForm: {
        expectJobs: '',
        expectSalary: '',
        expectSalaryCode: '',
        workAddress: {
          // 省
          provinces: '',
          provinceName: '',
          provinceCode: '',
          // 市
          citys: '',
          citysName: '',
          cityCode: '',
          // 镇
          towns: '',
          townName: '',
          townCode: '',
        },
        // 期望城市编码
        cityCode: '',
      },
      // 求职意愿规则
      jobobjective_editRules: {
        expectJobs: [{ required: true, message: '不能为空', trigger: 'blur' }],
        expectSalary: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
      },
      // 期望职业数据
      expectJobsOpt: [],
      // 期望薪资的数据
      expectSalaryList: [],
      // uid: 230,
      // 修改简历的参数
      bean: {
        expectJobs: 0,
        workArea: {
          province: '',
          provinceCode: '',
          city: '',
          cityCode: '',
          county: '',
          countyCode: '',
          longitude: '',
          latitude: '',
        },
        expectSalary: '',
        expectSalaryCode: '',
        avatarId: '',
        username: '',
        sex: '',
        sexCode: '',
        age: 0,
        workExp: 0,
        education: '',
        educationCode: '',
        phone: '',
        phoneCode: '',
        nativeArea: {
          province: '',
          provinceCode: '',
          city: '',
          cityCode: '',
          county: '',
          countyCode: '',
          longitude: '',
          latitude: '',
        },
        residenceArea: {
          province: '',
          provinceCode: '',
          city: '',
          cityCode: '',
          county: '',
          countyCode: '',
          longitude: '',
          latitude: '',
        },
        selfAssessment: '',
        resume: {
          fileToken: '',
          url: '',
          name: '',
          length: 0,
          sign: '',
        },
        privacySetting: 0,
        id: 0,
      },
      // 主键id
      id: 0,
      // 基本信息
      basicEditForm: {
        username: '',
        age: '',
        sex: '',
        sexCode: '',
        education: '',
        educationCode: '',
        workExp: 0,
        address: {
          // 省
          provinces: '',
          provinceName: '',
          provinceCode: '',
          // 市
          citys: '',
          citysName: '',
          cityCode: '',
          // 镇
          towns: '',
          townName: '',
          townCode: '',
        },
        nativePlace: {
          // 省
          provinces: '',
          provinceName: '',
          provinceCode: '',
          // 市
          citys: '',
          citysName: '',
          cityCode: '',
          // 镇
          towns: '',
          townName: '',
          townCode: '',
        },
      },
      // 基本信息规则
      basicEditRules: {
        username: [{ required: true, message: '不能为空', trigger: 'blur' }],
        age: [{ required: true, message: '不能为空', trigger: 'blur' }],
      },
      // 学历
      educations: [],
      // 工作年限
      workExpOptions: [
        {
          code: 1,
          name: '一年以上',
        },
        {
          code: 2,
          name: '两年以上',
        },
        {
          code: 3,
          name: '三年以上',
        },
        {
          code: 4,
          name: '四年以上',
        },
        {
          code: 5,
          name: '五年以上',
        },
        {
          code: 6,
          name: '六年以上',
        },
        {
          code: 7,
          name: '七年以上',
        },
        {
          code: 8,
          name: '八年以上',
        },
        {
          code: 9,
          name: '九年以上',
        },
        {
          code: 10,
          name: '十年以上',
        },
      ],
      // 自我介绍
      SelfEvaluationForm: {
        selfAssessment: '',
      },
      // 性别数据
      sexList: [],
      // 获取工作经历数组数据
      resumeperfectObj: [],
      // 获取学历数组数据
      schoolExperiences: [],
      rewardExperienceList: [],
      // 获取项目经验数组数据
      ProjectExperience: [],
      // 工作经历
      workExperienceForm: {
        // 职位
        positionName: '',
        // 薪资
        salary: 0,
        // 单位名称
        companyName: '',
        // 在职时间
        workEndDate: [],
        workStartDate: [],
        // 作业内容
        jobContent: '',
        achievement: '',
      },
      // 添加显示与隐藏
      workexpShowAdd: false,
      // 添加学历显示与隐藏
      shoolShowAdd: false,
      // 添加显示与隐藏
      ProjectShowAdd: false,
      // 添加家庭社会成员关系显示与隐藏
      familySocialRelationsShow: false,
      // 工作经历规则
      workExperienceRules: {
        companyName: [{ required: true, message: '不能为空', trigger: 'blur' }],
        positionName: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
      },
      // 学历规则
      schoolExperienceRules: {
        schoolName: [{ required: true, message: '不能为空', trigger: 'blur' }],
        majorName: [{ required: true, message: '不能为空', trigger: 'blur' }],
        graduateStartDate: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
      },
      jid: -1,
      sid: -1,
      pid: -1,
      fid: -1,
      // 教育经验
      schoolExperienceForm: {
        // 院校名称
        schoolName: '',
        // 专业名称
        majorName: '',
        // 毕业时间:
        graduateStartDate: '',
        graduateEndDate: '',
        // 在校经历
        schoolExperience: '',
        diplomaContentImgPath: '',
        diplomaContentImgThumbPath: '',
        diplomaCoverImgPath: '',
        diplomaCoverImgThumbPath: '',
      },
      // 奖项奖励
      rewardExperienceForm: {
        candidateId: 0,
        rewardName: '',
        rewardImg: '',
        issueUnit: '',
        awardDate: '',
        rewardImgThumbPath: '',
      },
      // 项目经验
      ProjectExperienceForm: {
        // 项目名称
        projectName: '',
        // 职位名称
        positionName: '',
        // 项目时间
        projectStartDate: [],
        // 项目介绍
        projectIntroduce: '',
        // 项目业绩
        projectAchievement: '',
      },
      // 项目经验规则
      ProjectExperienceRules: {
        entryName: [{ required: true, message: '不能为空', trigger: 'blur' }],
        JobTitle: [{ required: true, message: '不能为空', trigger: 'blur' }],
      },
      // 奖项规则
      RewardExperienceRules: {
        rewardName: [{ required: true, message: '不能为空', trigger: 'blur' }],
        issueUnit: [{ required: true, message: '不能为空', trigger: 'blur' }],
        awardDate: [{ required: true, message: '不能为空', trigger: 'blur' }],
      },
      // 上传简历token
      myHeaders: { Authorization: 'Bearer ' + token },
      //UploadeUrl地址
      uploadUrl: this.$uplodeUrl + '/utils/uploadfile',
      // 上传简历的地址
      uploadResume: this.$uplodeUrl + '/utils/uploadfile',
      // 上传的参数
      uploadResumeData: {
        fileType: 'candidate',
        fileTypeCode: '简历',
      },
      // 修改简历的参数
      attachmentFiles: [{ key: 0, value: '' }],
      // 上传简历列表
      fileList: [{ name: '', url: '' }],
      // 进度条数据
      percentage: 0,
      // 最后更新时间
      endUpdateDate: '',
      // 简历id
      candidateId: 0,
      // 城市列表数据
      cityList: cityjson,
      // 修改的主建id
      workId: 0,
      // 家庭称谓
      appellationValue: '',
      // 家庭称谓名称列表
      appellationList: [
        { value: '父亲', label: '父亲' },
        { value: '母亲', label: '母亲' },
        { value: '儿子', label: '儿子' },
        { value: '女儿', label: '女儿' },
        { value: '丈夫', label: '丈夫' },
        { value: '妻子', label: '妻子' },
      ],
      // 政治面貌
      politicalOutlook: '',
      // 政治面貌列表
      politicalOutlookList: [
        { value: '党员', label: '党员' },
        { value: '群众', label: '群众' },
      ],
      // 家庭社会成员关系
      familySocialRelationsForm: {
        relation: '',
        name: '',
        age: '',
        policitalStatus: '',
        workUnit: '',
        workPost: '',
        phone: '',
      },
      // 家庭社会成员关系表单规则
      familySocialRelationsRules: {
        relation: [{ required: true, message: '请选择称谓', trigger: 'blur' }],
        name: [{ required: true, message: '不能为空', trigger: 'blur' }],
        age: [{ required: true, message: '不能为空', trigger: 'blur' }],
        phone: [
          { required: true, message: '不能为空', trigger: 'blur' },
          { pattern: /^\d{11}$/, message: '格式错误，仅支持11位数字' },
        ],
      },
      // 获家庭社会成员关系数组数据
      relationExperienceList: [],
    };
  },
  mounted() {
    this.candidateId = this.$route.query.id;
    let candidateId = this.candidateId;
    this.onusergetcurruserinfo();
    this.getPerfectList(candidateId);
    this.getExpectJobs();
    this.getCandidatefilter();
    this.onprofessioncertlist();
  },
  methods: {
    dateFormat(time) {
      let date = new Date(time);
      let year = date.getFullYear();
      let month =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      return year + '/' + month + '/' + day;
    },
    onsubmit() {
      this.$message.success('提交成功!');
    },
    onusergetcurruserinfo() {
      usergetcurruserinfo().then((res) => {
        if (res.data.code == 1) {
          this.userinfo = res.data.result;
        }
      });
    },
    format(percentage) {
      return '';
    },
    // 选择居住地事件
    async getAddress(e) {
      this.getLoop(e[0], this.cityList);
      this.basicEditForm.address.provinceCode = e[0];
      this.basicEditForm.address.provinceName = this.place;
      // 市
      let code = e[0];
      const res = await ExpectJobs1(code);
      let countys = res.data.result.countys;
      this.getLoop1(e[1], countys);
      this.basicEditForm.address.cityCode = e[1];
      this.basicEditForm.address.citysName = this.place1;
      // 县
      let code1 = e[1];
      const res1 = await ExpectJobs1(code1);
      let countys1 = res1.data.result.countys;
      this.getLoop2(e[2], countys1);
      this.basicEditForm.address.townCode = e[2];
      this.basicEditForm.address.townName = this.place2;
    },
    onprofessioncertlist() {
      var that = this;
      var data = { verifyStatus: 1 };
      professioncertlist(data).then((res) => {
        if (res.data.code === 1) {
          that.professioncertlistdata = res.data.result;
        }
      });
    },
    // 选择籍贯事件
    async getNativePlace(e) {
      this.getLoop(e[0], this.cityList);
      this.basicEditForm.nativePlace.provinceCode = e[0];
      this.basicEditForm.nativePlace.provinceName = this.place;
      // 市
      let code = e[0];
      const res = await ExpectJobs1(code);
      let countys = res.data.result.countys;
      this.getLoop1(e[1], countys);
      this.basicEditForm.nativePlace.cityCode = e[1];
      this.basicEditForm.nativePlace.citysName = this.place1;
      // 县
      let code1 = e[1];
      const res1 = await ExpectJobs1(code1);
      let countys1 = res1.data.result.countys;
      this.getLoop2(e[2], countys1);
      this.basicEditForm.nativePlace.townCode = e[2];
      this.basicEditForm.nativePlace.townName = this.place2;
    },
    // 选择工作地点事件
    async getWorkAddress(e) {
      this.getLoop(e[0], this.cityList);
      this.jobobjective_editForm.workAddress.provinceCode = e[0];
      this.jobobjective_editForm.workAddress.provinceName = this.place;
      // 市
      let code = e[0];
      const res = await ExpectJobs1(code);
      let countys = res.data.result.countys;
      this.getLoop1(e[1], countys);
      this.jobobjective_editForm.workAddress.cityCode = e[1];
      this.jobobjective_editForm.workAddress.citysName = this.place1;
      // 县
      let code1 = e[1];
      const res1 = await ExpectJobs1(code1);
      let countys1 = res1.data.result.countys;
      this.getLoop2(e[2], countys1);
      this.jobobjective_editForm.workAddress.townCode = e[2];
      this.jobobjective_editForm.workAddress.townName = this.place2;
    },
    // 修改
    showworkexp_edit(index) {
      this.jid = index;
      this.workExperiencedialogVisible = true;
      this.workExperienceForm.companyName = this.resumeperfectObj[
        index
      ].companyName;
      this.workExperienceForm.id = this.resumeperfectObj[index].id;
      this.workExperienceForm.jobContent = this.resumeperfectObj[
        index
      ].jobContent;
      this.workExperienceForm.achievement = this.resumeperfectObj[
        index
      ].achievement;
      this.workExperienceForm.positionName = this.resumeperfectObj[
        index
      ].positionName;
      this.workExperienceForm.salary = this.resumeperfectObj[index].salary;
      this.workExperienceForm.workStartDate = this.resumeperfectObj[
        index
      ].workStartDate;
      this.workExperienceForm.workEndDate = this.resumeperfectObj[
        index
      ].workEndDate;
      // let starDate = this.$formatUTC(
      //   this.resumeperfectObj[index].workStartDate
      // );

      // let endDate = this.$formatUTC(this.resumeperfectObj[index].workEndDate);
      // let arr = this.workExperienceForm.workEndDate;
      // if (arr.length == 0) {
      //   arr.push(starDate, endDate);
      // } else if (arr.length > 0 && arr.length < 3) {
      //   arr.splice(0, arr.length);
      //   arr.push(starDate, endDate);
      // } else {
      //   return;
      // }
    },
    // 添加
    showworkexp_add() {
      this.workExperienceForm.positionName = '';
      this.workExperienceForm.salary = 0;
      this.workExperienceForm.companyName = '';
      this.workExperienceForm.workEndDate = '';
      this.workExperienceForm.workStartDate = '';
      this.workExperienceForm.jobContent = '';
      this.workExperienceForm.achievement = '';
      this.workExperienceForm.id = 0;
      this.workexpShowAdd = true;
      this.workExperiencedialogVisible = true;
    },
    // 工作经历删除
    async showworkexp_Delete(item) {
      let id = item.id;
      let jobLength = this.PerfectList.jobExperienceList.length;
      if (jobLength == 1) {
        this.percentage = this.percentage - 10;
      } else {
      }
      const { data: res } = await jobDelete(id);
      if (res.code == 1) {
        this.$message.success('删除成功!');
        this.getPercentage();
      } else {
        return this.$message.error('删除失败！');
      }
    },
    // 修改
    showSchool_edit(index) {
      this.schooldialogVisible = true;
      this.sid = index;
      this.schoolExperienceForm.schoolName = this.schoolExperiences[
        index
      ].schoolName;
      this.schoolExperienceForm.majorName = this.schoolExperiences[
        index
      ].majorName;
      this.schoolExperienceForm.schoolExperience = this.schoolExperiences[
        index
      ].schoolExperience;
      this.schoolExperienceForm.graduateStartDate = this.schoolExperiences[
        index
      ].graduateStartDate;
      this.schoolExperienceForm.graduateEndDate = this.schoolExperiences[
        index
      ].graduateEndDate;
      // let starDate = this.$formatUTC(
      //   this.schoolExperiences[index].graduateStartDate
      // );
      this.schoolExperienceForm.diplomaCoverImgThumbPath = this.schoolExperiences[
        index
      ].diplomaCoverImgThumbPath;

      this.schoolExperienceForm.diploma_cover = this.schoolExperiences[
        index
      ].diploma_cover;
      this.schoolExperienceForm.diplomaContentImgThumbPath = this.schoolExperiences[
        index
      ].diplomaContentImgThumbPath;
      this.schoolExperienceForm.diploma_content = this.schoolExperiences[
        index
      ].diploma_content;

      this.schoolExperienceForm.id = this.schoolExperiences[index].id;
      let endDate = this.$formatUTC(
        this.schoolExperiences[index].graduateEndDate
      );

      // console.log(this.schoolExperienceForm);
      // let arr = this.schoolExperienceForm.graduateStartDate;
      // if (arr.length == 0) {
      //   arr.push(starDate, endDate);
      // } else if (arr.length > 0 && arr.length < 3) {
      //   arr.splice(0, arr.length);
      //   arr.push(starDate, endDate);
      // } else {
      //   return;
      // }
    },
    // 添加学历
    showSchool_add() {
      this.schoolExperienceForm.schoolName = '';
      this.schoolExperienceForm.majorName = '';
      this.schoolExperienceForm.graduateStartDate = '';
      this.schoolExperienceForm.graduateEndDate = '';
      this.schoolExperienceForm.schoolExperience = '';
      this.schoolExperienceForm.diplomaContentImgPath = '';
      this.schoolExperienceForm.diploma_content = '';
      this.schoolExperienceForm.diplomaContentImgThumbPath = '';
      this.schoolExperienceForm.diplomaCoverImgPath = '';
      this.schoolExperienceForm.diploma_cover = '';
      this.schoolExperienceForm.diplomaCoverImgThumbPath = '';
      this.schoolExperienceForm.id = 0;
      this.shoolShowAdd = true;
      this.schooldialogVisible = true;
    },
    // 学历删除
    async showSchool_Delete(item) {
      let id = item.id;
      let educationLength = this.PerfectList.educationExperienceList.length;
      if (educationLength == 1) {
        this.percentage = this.percentage - 10;
      } else {
      }
      const { data: res } = await educationDelete(id);
      if (res.code == 1) {
        this.$message.success('删除成功!');
        this.getPercentage();
      } else {
        return this.$message.error('删除失败！');
      }
    },
    // 修改奖励
    showReward_edit(index) {
      this.rewarddialogVisible = true;
      this.sid = index;
      this.rewardExperienceForm.rewardName = this.rewardExperienceList[
        index
      ].rewardName;
      this.rewardExperienceForm.issueUnit = this.rewardExperienceList[
        index
      ].issueUnit;
      this.rewardExperienceForm.rewardImg = this.rewardExperienceList[
        index
      ].rewardImg;
      this.rewardExperienceForm.rewardImgThumbPath = this.rewardExperienceList[
        index
      ].rewardImgThumbPath;
      this.rewardExperienceForm.diploma_content = this.rewardExperienceList[
        index
      ].diploma_content;
      this.rewardExperienceForm.awardDate = this.rewardExperienceList[
        index
      ].awardDate;
      this.rewardExperienceForm.id = this.rewardExperienceList[index].id;
    },
    // 添加
    showReward_add() {
      this.rewardExperienceForm.rewardName = '';
      this.rewardExperienceForm.rewardImg = '';
      this.rewardExperienceForm.issueUnit = '';
      this.rewardExperienceForm.rewardImgThumbPath = '';
      this.rewardExperienceForm.awardDate = '';
      this.rewardExperienceForm.id = 0;
      this.rew = true;
      this.rewarddialogVisible = true;
    },
    // 删除奖项
    async showReward_Delete(item) {
      let id = item.id;
      const { data: res } = await delrewardexperience(id);
      if (res.code == 1) {
        this.$message.success('删除成功!');
        this.getPercentage();
      } else {
        return this.$message.error('删除失败！');
      }
    },

    // 自我分享
    SelfEvaluation_edit() {
      this.SelfEvaluationForm.selfAssessment = this.PerfectList.selfAssessment;
      this.SelfEvaluationShow = true;
    },
    // 修改
    showProject_edit(index) {
      this.pid = index;
      this.ProjectExperienceForm.projectName = this.ProjectExperience[
        index
      ].projectName;
      this.ProjectExperienceForm.positionName = this.ProjectExperience[
        index
      ].positionName;
      this.ProjectExperienceForm.projectIntroduce = this.ProjectExperience[
        index
      ].projectIntroduce;
      this.ProjectExperienceForm.projectAchievement = this.ProjectExperience[
        index
      ].projectAchievement;
      let starDate = this.$formatUTC(
        this.ProjectExperience[index].projectStartDate
      );
      let endDate = this.$formatUTC(
        this.ProjectExperience[index].projectEndDate
      );
      let arr = this.ProjectExperienceForm.projectStartDate;
      if (arr.length == 0) {
        arr.push(starDate, endDate);
      } else if (arr.length > 0 && arr.length < 3) {
        arr.splice(0, arr.length);
        arr.push(starDate, endDate);
      } else {
        return;
      }
    },
    // 添加
    showProject_add() {
      this.ProjectExperienceForm.projectName = '';
      this.ProjectExperienceForm.positionName = '';
      this.ProjectExperienceForm.projectStartDate = [];
      this.ProjectExperienceForm.projectIntroduce = '';
      this.ProjectExperienceForm.projectAchievement = '';
      this.ProjectShowAdd = true;
    },
    // 项目经验删除
    async showProject_Delete(item) {
      let id = item.id;
      let projectLength = this.PerfectList.projectExperienceList.length;
      if (projectLength == 1) {
        this.percentage = this.percentage - 10;
      } else {
      }
      const { data: res } = await projectDelete(id);
      if (res.code == 1) {
        this.$message.success('删除成功!');
        this.getPercentage();
      } else {
        return this.$message.error('删除失败！');
      }
    },
    // 修改家庭社会成员关系
    showfamily_edit(index) {
      this.fid = index;
      this.familydialogVisible = true;
      this.familySocialRelationsForm.relation = this.relationExperienceList[
        index
      ].relation;
      this.familySocialRelationsForm.name = this.relationExperienceList[
        index
      ].name;
      this.familySocialRelationsForm.age = this.relationExperienceList[
        index
      ].age;
      this.familySocialRelationsForm.policitalStatus = this.relationExperienceList[
        index
      ].policitalStatus;
      this.familySocialRelationsForm.workUnit = this.relationExperienceList[
        index
      ].workUnit;
      this.familySocialRelationsForm.workPost = this.relationExperienceList[
        index
      ].workPost;
      this.familySocialRelationsForm.phone = this.relationExperienceList[
        index
      ].phone;
      this.familySocialRelationsForm.id = this.relationExperienceList[index].id;
    },
    // 添加家庭社会成员关系
    showfamily_add() {
      this.familySocialRelationsForm.relation = '';
      this.familySocialRelationsForm.name = '';
      this.familySocialRelationsForm.age = '';
      this.familySocialRelationsForm.policitalStatus = '';
      this.familySocialRelationsForm.workUnit = '';
      this.familySocialRelationsForm.workPost = '';
      this.familySocialRelationsForm.phone = '';
      this.familySocialRelationsForm.id = 0;
      this.familydialogVisible = true;
    },
    // 家庭社会成员关系删除
    async showfamily_Delete(item) {
      let id = item.id;
      const { data: res } = await familyMemberDelete(id);
      if (res.code == 1) {
        this.$message.success('删除成功!');
        let candidateId = this.candidateId;
        this.getPerfectList(candidateId);
      } else {
        return this.$message.error('删除失败！');
      }
    },
    // 基本信息
    basicMeaasgeShow() {
      // 基本信息回显
      this.basicMeaasge = true;
      this.basicEditForm.username = this.PerfectList.username;
      this.basicEditForm.age = this.PerfectList.age;
      this.basicEditForm.sex = this.PerfectList.sex;
      this.basicEditForm.sexCode = this.PerfectList.sexCode;
      this.basicEditForm.education = this.PerfectList.education;
      this.basicEditForm.educationCode = this.PerfectList.educationcode;
      this.basicEditForm.workExp = this.PerfectList.workExp;

      this.basicEditForm.address.provinceName = this.PerfectList.dWellGisCoord.province;
      this.basicEditForm.address.provinceCode = this.PerfectList.dWellGisCoord.provinceCode;
      this.basicEditForm.address.citysName = this.PerfectList.dWellGisCoord.city;
      this.basicEditForm.address.cityCode = this.PerfectList.dWellGisCoord.cityCode;
      this.basicEditForm.address.townName = this.PerfectList.dWellGisCoord.county;
      this.basicEditForm.address.townCode = this.PerfectList.dWellGisCoord.countyCode;

      this.basicEditForm.nativePlace.provinceName = this.PerfectList.nativeGisCoord.province;
      this.basicEditForm.nativePlace.provinceCode = this.PerfectList.nativeGisCoord.provinceCode;
      this.basicEditForm.nativePlace.citysName = this.PerfectList.nativeGisCoord.city;
      this.basicEditForm.nativePlace.cityCode = this.PerfectList.nativeGisCoord.cityCode;
      this.basicEditForm.nativePlace.townName = this.PerfectList.nativeGisCoord.county;
      this.basicEditForm.nativePlace.townCode = this.PerfectList.nativeGisCoord.countyCode;
      this.basicEditForm.nativePlace = this.PerfectList.nativePlace;
    },
    // 求职意向
    showJobobjective() {
      this.jobobjectiveShow = true;
      // 求职意向回显
      this.jobobjective_editForm.expectJobs = this.PerfectList.expectJobs;
      this.jobobjective_editForm.expectSalary = this.PerfectList.expectSalary;
      this.jobobjective_editForm.expectSalaryCode = this.PerfectList.expectSalaryCode;

      this.jobobjective_editForm.workAddress.provinceName = this.PerfectList.workGisCoord.province;
      this.jobobjective_editForm.workAddress.provinceCode = this.PerfectList.workGisCoord.provinceCode;
      this.jobobjective_editForm.workAddress.cityCode = this.PerfectList.workGisCoord.cityCode;
      this.jobobjective_editForm.workAddress.citysName = this.PerfectList.workGisCoord.city;
      this.jobobjective_editForm.workAddress.townCode = this.PerfectList.workGisCoord.countyCode;
      this.jobobjective_editForm.workAddress.townName = this.PerfectList.workGisCoord.county;
    },
    // 获取求职列表的详情信息
    async getPerfectList(id) {
      const { data: res } = await candidate(id);
      if (res.code == 1) {
        this.PerfectList = res.result;
        this.id = res.result.id;
        // 头像的路径
        this.avatarIdPath = res.result.avatarPath;
        // 简历列表的数据
        this.fileList = res.result.resumeList;
        if (this.fileList !== null) {
          for (let i = 0; i < this.fileList.length; i++) {
            this.fileList[i].name = res.result.resumeList[i].fileName;
          }
        } else {
          this.fileList = [];
        }
        // 进度条数据
        this.percentage = this.PerfectList.candidateIntegrity;
        // 最后跟新时间
        this.endUpdateDate = this.PerfectList.endUpdateDate;
        // 获取工作经历数组数据
        this.resumeperfectObj = res.result.jobExperienceList;
        this.isEffective = res.result.isEffective;
        // for (let i = 0; i < this.resumeperfectObj.length; i++) {
        //   this.resumeperfectObj[i].starDate = this.$formatUTC(
        //     this.resumeperfectObj[i].workStartDate
        //   );
        //   this.resumeperfectObj[i].endDate = this.$formatUTC(
        //     this.resumeperfectObj[i].workEndDate
        //   );
        // }
        // 获取教育经验数组数据
        this.schoolExperiences = res.result.educationExperienceList;
        for (let i = 0; i < this.schoolExperiences.length; i++) {
          this.schoolExperiences[i].starDate = this.$formatUTC(
            this.schoolExperiences[i].graduateStartDate
          );
          this.schoolExperiences[i].endDate = this.$formatUTC(
            this.schoolExperiences[i].graduateEndDate
          );
        }
        // 获取奖项
        this.rewardExperienceList = res.result.rewardExperienceList;
        // 获取项目经验数组数据
        this.ProjectExperience = res.result.projectExperienceList;
        for (let i = 0; i < this.ProjectExperience.length; i++) {
          this.ProjectExperience[i].starDate = this.$formatUTC(
            this.ProjectExperience[i].projectStartDate
          );
          this.ProjectExperience[i].endDate = this.$formatUTC(
            this.ProjectExperience[i].projectEndDate
          );
        }
        // 获取家庭社会成员关系数组数据
        this.relationExperienceList = res.result.relationExperienceList;
        // this.getAvatarIdPath();
      } else {
        return this.$message.error('获取修改页面数据失败！');
      }
    },
    // 职业类别
    async getExpectJobs() {
      const { data: res } = await ExpectJobs();
      let Jobs = res.result.job;
      let newarr = [];
      this.handleData1(Jobs, newarr);
      this.expectJobsOpt = newarr;
    },
    // 级联选择器循环事件
    handleData(data, newarr) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        //如果子级存在,并且有数据
        if (data[i].children && data[i].children.length > 0) {
          //label为级联选择器要展示的值,我这里使用的是name,value为选中的值,我这里使用的是id,根据个人使用场景,按需调试
          newarr.push({ value: data[i].id, label: data[i].code, children: [] });
          this.handleData(data[i].children, newarr[i].children);
        } else {
          //子级不存在时,不用push  children:[],否则级联选择器最后一页会出现空白,很难看
          newarr.push({ value: data[i].id, label: data[i].code });
        }
      }
      return newarr;
    },
    handleData1(data, newarr) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        //如果子级存在,并且有数据
        if (data[i].children && data[i].children.length > 0) {
          //label为级联选择器要展示的值,我这里使用的是name,value为选中的值,我这里使用的是id,根据个人使用场景,按需调试
          newarr.push({ value: data[i].id, label: data[i].name, children: [] });
          this.handleData(data[i].children, newarr[i].children);
        } else {
          //子级不存在时,不用push  children:[],否则级联选择器最后一页会出现空白,很难看
          newarr.push({ value: data[i].id, label: data[i].name });
        }
      }
      return newarr;
    },
    // 职业类别级联选择器事件
    getCascader(e) {
      for (let i = 0; i < e.length; i++) {
        this.jobobjective_editForm.expectJobs = e[e.length - 1];
      }
    },
    // 循环地点名称事件
    getLoop(code, arr) {
      if (arr.length > 0) {
        arr.forEach((item) => {
          if (item.itemValue == code) {
            this.place = item.itemText;
          } else if (item.code == code) {
            this.place = item.name;
          } else if (item.value == code) {
            this.place = item.label;
          } else {
            return;
          }
        });
      }
    },
    getLoop1(code, arr) {
      if (arr.length > 0) {
        arr.forEach((item) => {
          if (item.itemValue == code) {
            this.place1 = item.itemText;
          } else if (item.code == code) {
            this.place1 = item.name;
          } else if (item.value == code) {
            this.place1 = item.label;
          } else {
            return;
          }
        });
      }
    },
    getLoop2(code, arr) {
      if (arr.length > 0) {
        arr.forEach((item) => {
          if (item.itemValue == code) {
            this.place2 = item.itemText;
          } else if (item.code == code) {
            this.place2 = item.name;
          } else if (item.value == code) {
            this.place2 = item.label;
          } else {
            return;
          }
        });
      }
    },
    // 期望薪资事件
    getexpectSalary(e) {
      this.getLoop(e, this.expectSalaryList);
      this.jobobjective_editForm.expectSalary = this.place;
      this.jobobjective_editForm.expectSalaryCode = e;
    },
    // 性别事件
    getSex(e) {
      this.getLoop(e, this.sexList);
      this.basicEditForm.sex = this.place;
      this.basicEditForm.sexCode = e;
    },

    // 求职意愿修改事件
    jobobjectiveEdit() {
      // 其它参数
      this.bean.username = this.PerfectList.username;
      this.bean.age = this.PerfectList.age;
      this.bean.sex = this.PerfectList.sex;
      this.bean.sexCode = this.PerfectList.sexCode;
      this.bean.education = this.PerfectList.education;
      this.bean.educationCode = this.PerfectList.educationcode;
      this.bean.workExp = this.PerfectList.workExp;
      this.bean.phone = this.PerfectList.phone;
      // 籍贯
      this.bean.nativeArea.province = this.PerfectList.nativeGisCoord.province;
      this.bean.nativeArea.provinceCode = this.PerfectList.nativeGisCoord.provinceCode;
      this.bean.nativeArea.city = this.PerfectList.nativeGisCoord.city;
      this.bean.nativeArea.cityCode = this.PerfectList.nativeGisCoord.cityCode;
      this.bean.nativeArea.county = this.PerfectList.nativeGisCoord.county;
      this.bean.nativeArea.countyCode = this.PerfectList.nativeGisCoord.countyCode;
      // 居住地 address
      this.bean.residenceArea.province = this.PerfectList.dWellGisCoord.province;
      this.bean.residenceArea.provinceCode = this.PerfectList.dWellGisCoord.provinceCode;
      this.bean.residenceArea.city = this.PerfectList.dWellGisCoord.city;
      this.bean.residenceArea.cityCode = this.PerfectList.dWellGisCoord.cityCode;
      this.bean.residenceArea.county = this.PerfectList.dWellGisCoord.county;
      this.bean.residenceArea.countyCode = this.PerfectList.dWellGisCoord.countyCode;

      this.bean.selfAssessment = this.PerfectList.selfAssessment;
      this.bean.avatarId = this.PerfectList.avatarid;
      this.bean.resume.fileToken = this.PerfectList.resumeList[0].fileToken;
      this.bean.resume.name = this.PerfectList.resumeList[0].fileName;
      this.bean.resume.length = this.PerfectList.resumeList[0].fileSize;
      this.bean.id = this.candidateId;
      // 求职意愿参数
      this.bean.expectJobs = this.jobobjective_editForm.expectJobs;
      this.bean.expectSalary = this.jobobjective_editForm.expectSalary;
      this.bean.expectSalaryCode = this.jobobjective_editForm.expectSalaryCode;
      this.bean.workArea.province = this.jobobjective_editForm.workAddress.provinceName;
      this.bean.workArea.provinceCode = this.jobobjective_editForm.workAddress.provinceCode;
      this.bean.workArea.city = this.jobobjective_editForm.workAddress.citysName;
      this.bean.workArea.cityCode = this.jobobjective_editForm.workAddress.cityCode;
      this.bean.workArea.county = this.jobobjective_editForm.workAddress.townName;
      this.bean.workArea.countyCode = this.jobobjective_editForm.workAddress.townCode;

      this.$refs.jobobjective_editRef.validate(async (valid) => {
        if (!valid) {
          return this.$message.error('请重新修改求职意向');
        } else {
          let bean = this.bean;
          const { data: res } = await postResumes(bean);
          if (res.code == 1) {
            this.$message.success('修改成功！');
            let candidateId = this.candidateId;
            this.getPerfectList(candidateId);
            this.jobobjectiveShow = false;
          } else {
            return this.$message.error('修改失败！');
          }
        }
      });
      // this.bean.candidateIntegrity = this.PerfectList.candidateIntegrity;
    },
    // 求职意愿表单重置
    jobResetFields() {
      this.jobobjectiveShow = false;
    },
    // 学历
    getEducation(e) {
      this.getLoop(e, this.educations);
      this.basicEditForm.education = this.place;
      this.basicEditForm.educationCode = e;
    },
    // 工作年限事件
    getworkExp(e) {
      this.getLoop(e, this.workExpOptions);
      this.form.workExp = this.place;
    },
    // 基本信息修改事件
    basicEdit() {
      // 其他参数
      this.bean.expectJobs = this.PerfectList.expectJobs;
      this.bean.workArea.province = this.PerfectList.workGisCoord.province;
      this.bean.workArea.provinceCode = this.PerfectList.workGisCoord.provinceCode;
      this.bean.workArea.city = this.PerfectList.workGisCoord.city;
      this.bean.workArea.cityCode = this.PerfectList.workGisCoord.cityCode;
      this.bean.workArea.county = this.PerfectList.workGisCoord.county;
      this.bean.workArea.countyCode = this.PerfectList.workGisCoord.countyCode;
      this.bean.expectSalary = this.PerfectList.expectSalary;
      this.bean.expectSalaryCode = this.PerfectList.expectSalaryCode;
      this.bean.selfAssessment = this.PerfectList.selfAssessment;
      this.bean.avatarId = this.PerfectList.avatarid;
      this.bean.phone = this.PerfectList.phone;
      this.bean.resume.fileToken = this.PerfectList.resumeList[0].fileToken;
      this.bean.resume.name = this.PerfectList.resumeList[0].fileName;
      this.bean.resume.length = this.PerfectList.resumeList[0].fileSize;

      // 基本信息参数
      this.bean.username = this.basicEditForm.username;
      this.bean.age = this.basicEditForm.age;
      this.bean.sex = this.basicEditForm.sex;
      this.bean.sexCode = this.basicEditForm.sexCode;
      this.bean.education = this.basicEditForm.education;
      this.bean.educationCode = this.basicEditForm.educationCode;
      this.bean.workExp = this.basicEditForm.workExp;
      // 籍贯
      this.bean.nativeArea.province = this.basicEditForm.nativePlace.provinceName;
      this.bean.nativeArea.provinceCode = this.basicEditForm.nativePlace.provinceCode;
      this.bean.nativeArea.city = this.basicEditForm.nativePlace.citysName;
      this.bean.nativeArea.cityCode = this.basicEditForm.nativePlace.cityCode;
      this.bean.nativeArea.county = this.basicEditForm.nativePlace.townName;
      this.bean.nativeArea.countyCode = this.basicEditForm.nativePlace.townCode;
      // 居住地 address
      this.bean.residenceArea.province = this.basicEditForm.address.provinceName;
      this.bean.residenceArea.provinceCode = this.basicEditForm.address.provinceCode;
      this.bean.residenceArea.city = this.basicEditForm.address.citysName;
      this.bean.residenceArea.cityCode = this.basicEditForm.address.cityCode;
      this.bean.residenceArea.county = this.basicEditForm.address.townName;
      this.bean.residenceArea.countyCode = this.basicEditForm.address.townCode;
      this.bean.id = this.candidateId;
      this.bean.candidateIntegrity = this.PerfectList.candidateIntegrity;
      //this.bean.
      this.$refs.basicEditRef.validate(async (valid) => {
        if (!valid) {
          return this.$message.error('请重新修改基本信息');
        } else {
          let bean = this.bean;
          const { data: res } = await postResumes(bean);
          if (res.code == 1) {
            this.$message.success('修改成功！');
            let candidateId = this.candidateId;
            this.getPerfectList(candidateId);
            this.basicMeaasge = false;
          } else {
            return this.$message.error('修改失败！');
          }
        }
      });
    },
    // 基本信息取消事件
    basicFields() {
      this.basicMeaasge = false;
    },
    // 自我介绍
    async selfAssessmentEdit() {
      // 自我介绍参数
      this.bean.selfAssessment = this.SelfEvaluationForm.selfAssessment;

      // 获取bean其他参数的值
      this.bean.username = this.PerfectList.username;
      this.bean.age = this.PerfectList.age;
      this.bean.sex = this.PerfectList.sex;
      this.bean.sexCode = this.PerfectList.sexCode;
      this.bean.education = this.PerfectList.education;
      this.bean.educationCode = this.PerfectList.educationcode;
      this.bean.workExp = this.PerfectList.workExp;
      // 籍贯
      this.bean.nativeArea.province = this.PerfectList.nativeGisCoord.province;
      this.bean.nativeArea.provinceCode = this.PerfectList.nativeGisCoord.provinceCode;
      this.bean.nativeArea.city = this.PerfectList.nativeGisCoord.city;
      this.bean.nativeArea.cityCode = this.PerfectList.nativeGisCoord.cityCode;
      this.bean.nativeArea.county = this.PerfectList.nativeGisCoord.county;
      this.bean.nativeArea.countyCode = this.PerfectList.nativeGisCoord.countyCode;
      // 居住地 address
      this.bean.residenceArea.province = this.PerfectList.dWellGisCoord.province;
      this.bean.residenceArea.provinceCode = this.PerfectList.dWellGisCoord.provinceCode;
      this.bean.residenceArea.city = this.PerfectList.dWellGisCoord.city;
      this.bean.residenceArea.cityCode = this.PerfectList.dWellGisCoord.cityCode;
      this.bean.residenceArea.county = this.PerfectList.dWellGisCoord.county;
      this.bean.residenceArea.countyCode = this.PerfectList.dWellGisCoord.countyCode;

      this.bean.expectJobs = this.PerfectList.expectJobs;
      this.bean.workArea.province = this.PerfectList.workGisCoord.province;
      this.bean.workArea.provinceCode = this.PerfectList.workGisCoord.provinceCode;
      this.bean.workArea.city = this.PerfectList.workGisCoord.city;
      this.bean.workArea.cityCode = this.PerfectList.workGisCoord.cityCode;
      this.bean.workArea.county = this.PerfectList.workGisCoord.county;
      this.bean.workArea.countyCode = this.PerfectList.workGisCoord.countyCode;
      this.bean.expectSalary = this.PerfectList.expectSalary;
      this.bean.expectSalaryCode = this.PerfectList.expectSalaryCode;
      this.bean.avatarId = this.PerfectList.avatarid;
      this.bean.phone = this.PerfectList.phone;
      this.bean.resume.fileToken = this.PerfectList.resumeList[0].fileToken;
      this.bean.resume.name = this.PerfectList.resumeList[0].fileName;
      this.bean.resume.length = this.PerfectList.resumeList[0].fileSize;

      this.bean.id = this.candidateId;
      let bean = this.bean;
      const { data: res } = await postResumes(bean);
      if (res.code == 1) {
        this.$message.success('修改成功！');
        let candidateId = this.candidateId;
        this.getPerfectList(candidateId);
        this.SelfEvaluationShow = false;
      } else {
        return this.$message.error('修改失败！');
      }
    },
    // 自我介绍取消事件
    selfAssessmentRes() {
      // this.SelfEvaluationForm.selfAssessment = '';
      this.SelfEvaluationShow = false;
    },
    // 工作经历表单按钮添加事件
    async workExperienceAdd() {
      let param = {};
      param.companyName = this.workExperienceForm.companyName;
      param.jobContent = this.workExperienceForm.jobContent;
      param.achievement = this.workExperienceForm.achievement;
      param.positionName = this.workExperienceForm.positionName;
      param.salary = this.workExperienceForm.salary;
      param.workEndDate = this.workExperienceForm.workEndDate;
      param.workStartDate = this.workExperienceForm.workStartDate;
      let candidateId = this.candidateId;
      param.candidateId = candidateId;
      const { data: res } = await job(param);
      if (res.code == 1) {
        this.$message.success('添加成功！');
        this.workExperiencedialogVisible = false;
        this.getPercentage();
        this.workId = res.result.id;
        this.workExperienceForm.positionName = '';
        this.workExperienceForm.salary = 0;
        this.workExperienceForm.companyName = '';
        this.workExperienceForm.workEndDate = '';
        this.workExperienceForm.workStartDate = '';
        this.workExperienceForm.jobContent = '';
        this.workExperienceForm.achievement = '';
        this.workExperienceForm.id = 0;
        this.workexpShowAdd = false;
      } else {
        return this.$message.error(res.message);
      }
    },
    // 工作经历表单按钮修改事件
    async workExperienceEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            this.workExperienceForm.workEndDate <
            this.workExperienceForm.workStartDate
          ) {
            this.$message({
              message: '开始时间不能大于结束时间',
              type: 'warning',
            });
            return;
          }

          if (this.workExperienceForm.id == 0) {
            this.workExperienceAdd();
          } else {
            let param = {};
            param.companyName = this.workExperienceForm.companyName;
            param.jobContent = this.workExperienceForm.jobContent;
            param.achievement = this.workExperienceForm.achievement;
            param.positionName = this.workExperienceForm.positionName;
            param.salary = this.workExperienceForm.salary;
            param.workEndDate = this.workExperienceForm.workEndDate;
            param.workStartDate = this.workExperienceForm.workStartDate;
            param.id = this.workExperienceForm.id;
            let candidateId = this.candidateId;
            param.candidateId = candidateId;
            job(param).then((res) => {
              if (res.data.code == 1) {
                this.$message.success('修改成功！');
                let candidateId = this.candidateId;
                this.workExperiencedialogVisible = false;
                this.getPerfectList(candidateId);
                this.workExperienceForm.positionName = '';
                this.workExperienceForm.salary = 0;
                this.workExperienceForm.companyName = '';
                this.workExperienceForm.workEndDate = '';
                this.workExperienceForm.workStartDate = '';
                this.workExperienceForm.jobContent = '';
                this.workExperienceForm.achievement = '';
              } else {
                return this.$message.error(res.data.message);
              }
            });
          }
        }
      });
    },
    // 工作经历表单按钮取消事件
    workExperienceRes1(index) {
      this.workExperienceForm.positionName = '';
      this.workExperienceForm.salary = 0;
      this.workExperienceForm.companyName = '';
      this.workExperienceForm.workEndDate = '';
      this.workExperienceForm.workStartDate = '';
      this.workExperienceForm.jobContent = '';
      this.workExperienceForm.achievement = '';
      //this.jid = index - 100;
      this.workExperiencedialogVisible = false;
    },
    workExperienceRes2() {
      this.workExperienceForm.positionName = '';
      this.workExperienceForm.salary = 0;
      this.workExperienceForm.companyName = '';
      this.workExperienceForm.workEndDate = [];
      this.workExperienceForm.jobContent = '';
      this.workExperienceForm.achievement = '';
      this.workexpShowAdd = false;
    },
    // 教育经验表单按钮添加事件
    async schoolExperienceAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            this.schoolExperienceForm.graduateEndDate <
            this.schoolExperienceForm.graduateStartDate
          ) {
            this.$message({
              message: '开始时间不能大于结束时间',
              type: 'warning',
            });
            return;
          }

          if (this.schoolExperienceForm.id > 0) {
            this.schoolExperienceEdit();
          } else {
            let param = {};
            param.schoolName = this.schoolExperienceForm.schoolName;
            param.majorName = this.schoolExperienceForm.majorName;
            param.schoolExperience = this.schoolExperienceForm.schoolExperience;
            param.graduateEndDate = this.schoolExperienceForm.graduateEndDate;
            param.graduateStartDate = this.schoolExperienceForm.graduateStartDate;
            param.diploma_cover = this.schoolExperienceForm.diploma_cover;
            param.diploma_content = this.schoolExperienceForm.diploma_content;

            let educationLength = this.PerfectList.educationExperienceList
              .length;
            if (educationLength == 0) {
              this.percentage = this.percentage + 10;
            } else {
            }
            let candidateId = this.candidateId;
            param.candidateId = candidateId;
            education(param).then((res) => {
              if (res.data.code == 1) {
                this.$message.success('添加成功！');
                this.getPercentage();
                this.schoolExperienceForm.schoolName = '';
                this.schoolExperienceForm.majorName = '';
                this.schoolExperienceForm.graduateStartDate = '';
                this.schoolExperienceForm.graduateEndDate = '';
                this.schoolExperienceForm.schoolExperience = '';
                this.shoolShowAdd = false;
                this.schooldialogVisible = false;
              } else {
                return this.$message.error(res.data.message);
              }
            });
          }
        }
      });
    },
    // 教育经验表单按钮修改事件
    async schoolExperienceEdit() {
      let param = {};
      param.schoolName = this.schoolExperienceForm.schoolName;
      param.majorName = this.schoolExperienceForm.majorName;
      param.schoolExperience = this.schoolExperienceForm.schoolExperience;
      param.graduateEndDate = this.schoolExperienceForm.graduateEndDate;
      param.graduateStartDate = this.schoolExperienceForm.graduateStartDate;
      param.diploma_cover = this.schoolExperienceForm.diploma_cover;
      param.diploma_content = this.schoolExperienceForm.diploma_content;
      param.id = this.schoolExperienceForm.id;

      let candidateId = this.candidateId;
      param.candidateId = candidateId;
      const { data: res } = await education(param);
      if (res.code == 1) {
        this.$message.success('修改成功！');
        let candidateId = this.candidateId;
        this.getPerfectList(candidateId);
        this.schoolExperienceForm.schoolName = '';
        this.schoolExperienceForm.majorName = '';
        this.schoolExperienceForm.graduateStartDate = '';
        this.schoolExperienceForm.graduateEndDate = '';
        this.schoolExperienceForm.schoolExperience = '';
        this.schooldialogVisible = false;
      } else {
        return this.$message.error('修改失败！');
      }
    },
    // 奖项新增
    async rewardExperienceAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.rewardExperienceForm.id > 0) {
            this.rewardExperienceEdit();
          } else {
            let param = {};
            param.rewardName = this.rewardExperienceForm.rewardName;
            param.issueUnit = this.rewardExperienceForm.issueUnit;
            param.rewardImg = this.rewardExperienceForm.rewardImg;
            param.awardDate = this.rewardExperienceForm.awardDate;
            let candidateId = this.candidateId;
            param.candidateId = candidateId;
            addorupdaterewardexperience(param).then((res) => {
              if (res.data.code == 1) {
                this.$message.success('添加成功！');
                this.getPercentage();
                this.rewardExperienceForm.rewardName = '';
                this.rewardExperienceForm.issueUnit = '';
                this.rewardExperienceForm.rewardImg = '';
                this.rewardExperienceForm.awardDate = '';
                this.rewardExperienceForm.id = 0;
                this.rewarddialogVisible = false;
              } else {
                return this.$message.error(res.data.message);
              }
            });
          }
        }
      });
    },
    // 奖项修改
    async rewardExperienceEdit() {
      let param = {};
      param.rewardName = this.rewardExperienceForm.rewardName;
      param.issueUnit = this.rewardExperienceForm.issueUnit;
      param.rewardImg = this.rewardExperienceForm.rewardImg;
      param.awardDate = this.rewardExperienceForm.awardDate;
      param.id = this.rewardExperienceForm.id;

      let candidateId = this.candidateId;
      param.candidateId = candidateId;
      const { data: res } = await addorupdaterewardexperience(param);
      if (res.code == 1) {
        this.$message.success('修改成功！');
        this.getPercentage();
        this.rewardExperienceForm.rewardName = '';
        this.rewardExperienceForm.issueUnit = '';
        this.rewardExperienceForm.rewardImg = '';
        this.rewardExperienceForm.awardDate = '';
        this.rewarddialogVisible = false;
      } else {
        return this.$message.error(res.message);
      }
    },
    // 教育经验表单按钮取消事件
    rewardExperienceRes1(index) {
      this.rewardExperienceForm.rewardName = '';
      this.rewardExperienceForm.issueUnit = '';
      this.rewardExperienceForm.rewardImg = '';
      this.rewardExperienceForm.awardDate = '';
      this.rewardExperienceForm.id = 0;
      this.rewarddialogVisible = false;
    },
    schoolExperienceRes2() {
      this.schoolExperienceForm.schoolName = '';
      this.schoolExperienceForm.majorName = '';
      this.schoolExperienceForm.graduateStartDate = [];
      this.schoolExperienceForm.schoolExperience = '';
      this.shoolShowAdd = false;
      this.schooldialogVisible = false;
    },
    // 项目经验表单按钮添加事件
    async ProjectExperienceAdd() {
      let param = {};
      param.projectName = this.ProjectExperienceForm.projectName;
      param.positionName = this.ProjectExperienceForm.positionName;
      param.projectAchievement = this.ProjectExperienceForm.projectAchievement;
      param.projectIntroduce = this.ProjectExperienceForm.projectIntroduce;
      param.projectEndDate = this.ProjectExperienceForm.projectStartDate[1];
      param.projectStartDate = this.ProjectExperienceForm.projectStartDate[0];

      let projectLength = this.PerfectList.projectExperienceList.length;
      if (projectLength == 0) {
        this.percentage = this.percentage + 10;
      } else {
      }
      let candidateId = this.candidateId;
      param.candidateId = candidateId;
      const { data: res } = await projectAdd(param);
      if (res.code == 1) {
        this.$message.success('添加成功！');

        this.getPercentage();
        this.ProjectExperienceForm.projectName = '';
        this.ProjectExperienceForm.positionName = '';
        this.ProjectExperienceForm.projectAchievement = '';
        this.ProjectExperienceForm.projectIntroduce = '';
        this.ProjectExperienceForm.projectStartDate = [];
        this.ProjectShowAdd = false;
      } else {
        return this.$message.error('添加失败！');
      }
    },
    // 项目经验表单按钮修改事件
    async ProjectExperienceEdit(item, index) {
      let param = {};
      param.projectName = this.ProjectExperienceForm.projectName;
      param.positionName = this.ProjectExperienceForm.positionName;
      param.projectAchievement = this.ProjectExperienceForm.projectAchievement;
      param.projectIntroduce = this.ProjectExperienceForm.projectIntroduce;
      param.projectEndDate = this.ProjectExperienceForm.projectStartDate[1];
      param.projectStartDate = this.ProjectExperienceForm.projectStartDate[0];
      param.id = item.id;

      let candidateId = this.candidateId;
      param.candidateId = candidateId;
      const { data: res } = await projectAdd(param);
      if (res.code == 1) {
        this.$message.success('修改成功！');
        let candidateId = this.candidateId;
        this.getPerfectList(candidateId);
        this.ProjectExperienceForm.projectName = '';
        this.ProjectExperienceForm.positionName = '';
        this.ProjectExperienceForm.projectAchievement = '';
        this.ProjectExperienceForm.projectIntroduce = '';
        this.ProjectExperienceForm.projectStartDate = [];
      } else {
        return this.$message.error('修改失败！');
      }
      this.pid = index - 100;
    },
    // 项目经验表单按钮取消事件
    ProjectExperience1(index) {
      this.ProjectExperienceForm.projectName = '';
      this.ProjectExperienceForm.positionName = '';
      this.ProjectExperienceForm.projectStartDate = [];
      this.ProjectExperienceForm.projectIntroduce = '';
      this.ProjectExperienceForm.projectAchievement = '';
      this.pid = index - 100;
    },
    ProjectExperience2() {
      this.ProjectExperienceForm.projectName = '';
      this.ProjectExperienceForm.positionName = '';
      this.ProjectExperienceForm.projectStartDate = [];
      this.ProjectExperienceForm.projectIntroduce = '';
      this.ProjectExperienceForm.projectAchievement = '';
      this.ProjectShowAdd = false;
    },
    // 上传简历成功时的钩子
    async resumeSuccess(response, file, fileList) {
      // 获取bean其他参数的值
      this.bean.username = this.PerfectList.username;
      this.bean.age = this.PerfectList.age;
      this.bean.sex = this.PerfectList.sex;
      this.bean.sexCode = this.PerfectList.sexCode;
      this.bean.education = this.PerfectList.education;
      this.bean.educationCode = this.PerfectList.educationcode;
      this.bean.workExp = this.PerfectList.workExp;
      // 籍贯
      this.bean.nativeArea.province = this.PerfectList.nativeGisCoord.province;
      this.bean.nativeArea.provinceCode = this.PerfectList.nativeGisCoord.provinceCode;
      this.bean.nativeArea.city = this.PerfectList.nativeGisCoord.city;
      this.bean.nativeArea.cityCode = this.PerfectList.nativeGisCoord.cityCode;
      this.bean.nativeArea.county = this.PerfectList.nativeGisCoord.county;
      this.bean.nativeArea.countyCode = this.PerfectList.nativeGisCoord.countyCode;
      // 居住地 address
      this.bean.residenceArea.province = this.PerfectList.dWellGisCoord.province;
      this.bean.residenceArea.provinceCode = this.PerfectList.dWellGisCoord.provinceCode;
      this.bean.residenceArea.city = this.PerfectList.dWellGisCoord.city;
      this.bean.residenceArea.cityCode = this.PerfectList.dWellGisCoord.cityCode;
      this.bean.residenceArea.county = this.PerfectList.dWellGisCoord.county;
      this.bean.residenceArea.countyCode = this.PerfectList.dWellGisCoord.countyCode;

      this.bean.expectJobs = this.PerfectList.expectJobs;
      this.bean.workArea.province = this.PerfectList.workGisCoord.province;
      this.bean.workArea.provinceCode = this.PerfectList.workGisCoord.provinceCode;
      this.bean.workArea.city = this.PerfectList.workGisCoord.city;
      this.bean.workArea.cityCode = this.PerfectList.workGisCoord.cityCode;
      this.bean.workArea.county = this.PerfectList.workGisCoord.county;
      this.bean.workArea.countyCode = this.PerfectList.workGisCoord.countyCode;
      this.bean.expectSalary = this.PerfectList.expectSalary;
      this.bean.expectSalaryCode = this.PerfectList.expectSalaryCode;
      this.bean.avatarId = this.PerfectList.avatarid;
      this.bean.phone = this.PerfectList.phone;
      this.bean.resume.fileToken = response.result.fileToken;
      this.bean.resume.name = response.result.name;
      this.bean.resume.length = response.result.length;
      // 自我介绍参数
      this.bean.selfAssessment = this.PerfectList.selfAssessment;
      // 进度条参数
      this.bean.candidateIntegrity = this.PerfectList.candidateIntegrity;

      this.bean.id = this.candidateId;
      let bean = this.bean;
      const { data: res } = await postResumes(bean);
      if (res.code == 1) {
        let candidateId = this.candidateId;
        this.getPerfectList(candidateId);
      } else {
        return;
      }
    },
    // 上传简历失败时
    resumeError(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传简历失败');
    },
    // 求职过滤条件
    async getCandidatefilter() {
      const { data: res } = await ExpectJobs();
      if (res.code == 1) {
        let expectSalaryList = res.result.sals;
        this.expectSalaryList = expectSalaryList.slice(
          1,
          expectSalaryList.length - 1
        );
        let educations = res.result.edus;
        this.educations = educations.slice(1, educations.length - 1);
        this.sexList = res.result.sexs;
      } else {
        this.$message.error('过滤条件获取失败！');
      }
    },
    async handlediplomaContentImgThumbPathSuccess(response, file) {
      response.result.url + '/raw';
      this.schoolExperienceForm.diplomaContentImgThumbPath =
        response.result.url + '/raw';
      this.schoolExperienceForm.diploma_content = response.result.fileToken;
    },
    async handlerewardImguccess(response, file) {
      this.rewardExperienceForm.rewardImgPath = response.result.url + '/raw';
      this.rewardExperienceForm.rewardImgThumbPath =
        response.result.url + '/raw';
      this.rewardExperienceForm.rewardImg = response.result.fileToken;
    },
    async handlediplomaCoverImgPathSuccess(response, file) {
      this.schoolExperienceForm.diplomaCoverImgPath =
        response.result.url + '/raw';
      this.schoolExperienceForm.diplomaCoverImgThumbPath =
        response.result.url + '/raw';
      this.schoolExperienceForm.diploma_cover = response.result.fileToken;
    },
    // 上传头像成功时的事件
    async handleAvatarSuccess(response, file) {
      // this.imageUrl = file.url;
      let avatarId = response.result.fileToken;
      // 上传头像的id
      this.bean.avatarId = avatarId;

      // 获取bean其他参数的值
      this.bean.username = this.PerfectList.username;
      this.bean.age = this.PerfectList.age;
      this.bean.sex = this.PerfectList.sex;
      this.bean.sexCode = this.PerfectList.sexCode;
      this.bean.education = this.PerfectList.education;
      this.bean.educationCode = this.PerfectList.educationcode;
      this.bean.workExp = this.PerfectList.workExp;
      // 籍贯
      this.bean.nativeArea.province = this.PerfectList.nativeGisCoord.province;
      this.bean.nativeArea.provinceCode = this.PerfectList.nativeGisCoord.provinceCode;
      this.bean.nativeArea.city = this.PerfectList.nativeGisCoord.city;
      this.bean.nativeArea.cityCode = this.PerfectList.nativeGisCoord.cityCode;
      this.bean.nativeArea.county = this.PerfectList.nativeGisCoord.county;
      this.bean.nativeArea.countyCode = this.PerfectList.nativeGisCoord.countyCode;
      // 居住地 address
      this.bean.residenceArea.province = this.PerfectList.dWellGisCoord.province;
      this.bean.residenceArea.provinceCode = this.PerfectList.dWellGisCoord.provinceCode;
      this.bean.residenceArea.city = this.PerfectList.dWellGisCoord.city;
      this.bean.residenceArea.cityCode = this.PerfectList.dWellGisCoord.cityCode;
      this.bean.residenceArea.county = this.PerfectList.dWellGisCoord.county;
      this.bean.residenceArea.countyCode = this.PerfectList.dWellGisCoord.countyCode;

      this.bean.expectJobs = this.PerfectList.expectJobs;
      this.bean.workArea.province = this.PerfectList.workGisCoord.province;
      this.bean.workArea.provinceCode = this.PerfectList.workGisCoord.provinceCode;
      this.bean.workArea.city = this.PerfectList.workGisCoord.city;
      this.bean.workArea.cityCode = this.PerfectList.workGisCoord.cityCode;
      this.bean.workArea.county = this.PerfectList.workGisCoord.county;
      this.bean.workArea.countyCode = this.PerfectList.workGisCoord.countyCode;
      this.bean.expectSalary = this.PerfectList.expectSalary;
      this.bean.expectSalaryCode = this.PerfectList.expectSalaryCode;

      this.bean.selfAssessment = this.PerfectList.selfAssessment;
      this.bean.phone = this.PerfectList.phone;
      this.bean.resume.fileToken = this.PerfectList.resumeList[0].fileToken;
      this.bean.resume.name = this.PerfectList.resumeList[0].fileName;
      this.bean.resume.length = this.PerfectList.resumeList[0].fileSize;

      this.bean.id = this.candidateId;
      let bean = this.bean;
      const { data: res } = await postResumes(bean);
      if (res.code == 1) {
        this.$message.success('修改成功！');
        let candidateId = this.candidateId;
        this.getPerfectList(candidateId);
        this.SelfEvaluationShow = false;
      } else {
        return this.$message.error('修改失败！');
      }
    },
    // 进度条数据保存percentage
    async getPercentage() {
      this.getPerfectList(this.candidateId);
    },
    // 家庭社会成员关系添加表单事件
    async familySocialRelationsAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.familySocialRelationsForm.id > 0) {
            this.familySocialRelationsAdd1();
          } else {
            let param = {};
            param.relation = this.familySocialRelationsForm.relation;
            param.name = this.familySocialRelationsForm.name;
            param.age = this.familySocialRelationsForm.age;
            param.policitalStatus = this.familySocialRelationsForm.policitalStatus;
            param.workUnit = this.familySocialRelationsForm.workUnit;
            param.workPost = this.familySocialRelationsForm.workPost;
            param.phone = this.familySocialRelationsForm.phone;
            param.candidateId = this.candidateId;
            familyMember(param).then((res) => {
              console.log(res);
              if (res.data.code == 1) {
                this.$message.success('添加成功');
                this.getPercentage();
                this.familySocialRelationsForm.relation = '';
                this.familySocialRelationsForm.name = '';
                this.familySocialRelationsForm.age = '';
                this.familySocialRelationsForm.policitalStatus = '';
                this.familySocialRelationsForm.workUnit = '';
                this.familySocialRelationsForm.workPost = '';
                this.familySocialRelationsForm.phone = '';
                this.familySocialRelationsShow = false;
                this.familydialogVisible = false;
              } else {
                  return this.$message.error(res.data.message);
              }
            });
          }
        }
      });
    },
    // 家庭社会成员关系修改表单事件
    async familySocialRelationsAdd1() {
      let param = {};
      param.relation = this.familySocialRelationsForm.relation;
      param.name = this.familySocialRelationsForm.name;
      param.age = this.familySocialRelationsForm.age;
      param.policitalStatus = this.familySocialRelationsForm.policitalStatus;
      param.workUnit = this.familySocialRelationsForm.workUnit;
      param.workPost = this.familySocialRelationsForm.workPost;
      param.phone = this.familySocialRelationsForm.phone;
      param.id = this.familySocialRelationsForm.id;
      param.candidateId = this.candidateId;
      const { data: res } = await familyMember(param);
      if (res.code == 1) {
        this.$message.success('修改成功');
        let candidateId = this.candidateId;
        this.getPerfectList(candidateId);
        this.familySocialRelationsForm.relation = '';
        this.familySocialRelationsForm.name = '';
        this.familySocialRelationsForm.age = '';
        this.familySocialRelationsForm.policitalStatus = '';
        this.familySocialRelationsForm.workUnit = '';
        this.familySocialRelationsForm.workPost = '';
        this.familySocialRelationsForm.phone = '';
        this.familydialogVisible = false;
      } else {
        return this.$message.error(res.data.message);
      }
    },
    // 家庭社会成员关系表单按钮取消事件
    familyExperience() {
      this.familydialogVisible = false;
      this.familySocialRelationsForm.relation = '';
      this.familySocialRelationsForm.name = '';
      this.familySocialRelationsForm.age = '';
      this.familySocialRelationsForm.policitalStatus = '';
      this.familySocialRelationsForm.workUnit = '';
      this.familySocialRelationsForm.workPost = '';
      this.familySocialRelationsForm.phone = '';
      this.familySocialRelationsShow = false;
    },
    familyExperience1(index) {
      this.familySocialRelationsForm.relation = '';
      this.familySocialRelationsForm.name = '';
      this.familySocialRelationsForm.age = '';
      this.familySocialRelationsForm.policitalStatus = '';
      this.familySocialRelationsForm.workUnit = '';
      this.familySocialRelationsForm.workPost = '';
      this.familySocialRelationsForm.phone = '';
      this.fid = index - 100;
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: resumeicon;
  src: url(../../assets/fonts/resumeicon.eot);
  src: url(../../assets/fonts/resumeicon.eot?#iefix) format('embedded-opentype'),
    url(../../assets/fonts/resumeicon.woff) format('woff'),
    url(../../assets/fonts/resumeicon.ttf) format('truetype'),
    url(../../assets/fonts/resumeicon.svg) format('svg');
}
/* 公共样式开始 */
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 880px;
  margin-right: 60px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .titleL {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  font-weight: 400;
  border-bottom: 1px solid #e0e0e0;
}
.accountset .item-block .titleL em {
  color: #404040;
  margin-left: 10px;
  font-style: normal;
}
.accountset .item-block .titleL em a{
color: #0d7bff;
}

.accountset .info-cnt {
  padding: 30px 0;
}
.accountset .content-rb .empty {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
.accountset .content-rb .empty i {
  font-size: 40px;
  color: #ddd;
  width: 100%;
  display: inline-block;
}
.accountset .content-rb .empty span {
  width: 100%;
  display: inline-block;
  font-size: 16px;
  color: #ddd;
}
.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
/* 公共样式结束 */
.functionUnit {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
.annex {
  width: 30%;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  padding: 16px 18px;
  box-sizing: border-box;
  margin-left: 10px;
}
.annex .upload-title {
  height: 21px;
  line-height: 21px;
  font-size: 16px;
  color: #666;
  text-align: left;
}
.annex .upload-btn {
  width: 174px;
  height: 40px;
  line-height: 40px;
  background: #ff552e;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  margin: 14px 0 10px 0;
  cursor: pointer;
  text-align: center;
}
.annex .upload-explain {
  line-height: 16px;
  font-size: 12px;
  color: #aaa;
}
.completion {
  padding: 20px;
  width: 30%;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  padding: 16px 18px;
  box-sizing: border-box;
}
.completion .titles {
  font-family: PingFangSC-Semibold, Microsoft YaHei;
  font-size: 16px;
  color: #666;
  text-align: left;
  margin-bottom: 5px;
}
.completion .titles span {
  color: #ff552d;
}
.completion .desc {
  margin-top: 10px;
  font-family: PingFangSC-Regular, Microsoft YaHei;
  font-size: 12px;
  color: #aaa;
  text-align: left;
}
.resumeTabs {
  margin-top: 30px;
}
.updatedate {
  padding: 0 30px;
  height: 60px;
  text-align: left;
  line-height: 60px;
  font-family: PingFangSC-Regular, Microsoft YaHei;
  font-size: 14px;
  color: #999;
}
.el-main {
  border: 1px solid #eee;
  border-bottom-color: transparent;
  margin-left: 20px;
  padding: 0;
}

.infos {
  width: 90%;
  position: relative;
  padding: 26px 30px 10px;
  overflow: hidden;
}

.infos .title h2 {
  display: inline-block;
  font-family: PingFangSC-Semibold, Microsoft YaHei;
  font-size: 20px;
  color: #333;
  line-height: 32px;
  font-weight: 400;
  position: relative;
}
.infos .title.intotitle h2::before {
  content: '';
  position: absolute;
  width: 3px;
  height: 19px;
  border-radius: 2px;
  background-color: #268eff;
  left: -8px;
  top: 8px;
}

.infos .title h4 {
  display: inline-block;
  margin-left: 5px;
  color: #666;
  font-weight: 400;
}
.infos i {
  margin-right: 4px;
  font-size: 14px;
  color: #999;
  font-family: resumeicon !important;
  font-size: 12px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.infos .btn {
  margin-right: 70px;
}
.infos .btn div {
  margin-right: 10px;
  margin-top: 15px;
  float: right;
  width: 56px;
  height: 24px;
  background: #ff552d;
  border-radius: 2px;
  font-family: PingFangSC-Regular, Microsoft YaHei;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
}
.infos .btn i {
  color: #fff !important;
}
.infos .commonaddbtn {
  margin: 0 auto;
  padding: 0 5px;
  width: 140px;
  height: 38px;
  background: #f8f9fb;
  border-radius: 2px;
  font-family: PingFangSC-Regular, Microsoft YaHei;
  font-size: 14px;
  color: #666;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
  margin-top: 20px;
}
.infos .commonaddbtn i {
  font-size: 14px;
  color: #ccc;
  padding-right: 5px;
}

.basic {
  border-bottom: 1px solid #eee;
  display: flex;
}
.basic .infos {
  width: 90%;
}
.basic .infos .title h2 {
  font-size: 24px;
}
.basic .infos ul {
  margin-top: 14px;
}
.basic .infos li {
  float: left;
  height: 32px;
  font-family: PingFangSC-Regular, Microsoft YaHei;
  font-size: 14px;
  color: #666;
  text-align: left;
}
.basic .head {
  width: 110px;
  height: 110px;
  position: relative;
  border-radius: 50%;
  margin-left: -25px;
  margin-top: 33px;
}
.basic .head img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.required {
  font-family: Tahoma;
  font-size: 14px;
  color: red;
  line-height: 14px;
}
.edit {
  padding: 0px 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
}
.edit .title {
  font-family: PingFangSC-Semibold, Microsoft YaHei;
  font-size: 20px;
  color: #333;
  line-height: 28px;
}
.edit .el-form {
  margin-top: 30px;
}
.edit .el-form .controly {
  width: 200px;
}
.edit .el-form .control {
  width: 300px;
}
.edit .el-form .controlx {
  width: 483px;
}
.edit .el-form .controls {
  width: 86%;
}
.controls .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.edit .el-button {
  min-width: 80px;
}
.edit .el-button--primary {
  background-color: #ff552d;
  border: 1px solid #ff552d;
}

.jobobjective .content {
  margin-top: 50px;
}

.jobobjective .content span {
  font-family: PingFangSC-Regular, Microsoft YaHei;
  font-size: 14px;
  color: #333;
  text-align: left;
}
.jobobjective .content i {
  font-size: 12px;
  color: #cdcdcd;
  padding: 0 10px;
}

.workexp .content {
  padding-top: 14px;
}
.workexp .content dt {
  float: left;
  font-size: 14px;
  color: #999;
  line-height: 20px;
  vertical-align: top;
  width: 80px;
}
.workexp .content dd {
  float: left;
  font-size: 14px;
  color: #666;
  line-height: 20px;
  word-break: break-all;
  word-wrap: break-word;
}
.workexp .content .com {
  padding-top: 12px;
  font-family: PingFangSC-Semibold, Microsoft YaHei;
  font-weight: 700;
  font-size: 16px;
  color: #333;
  line-height: 28px;
  width: auto;
}
.workexp .content dd.com {
  font-size: 14px;
  color: #999;
  line-height: 20px;
  margin: 6px 0 0 5px;
}
.school {
  margin-top: 35px;
  word-break: break-all;
  display: flex;
  flex-direction: column;
}
.schools {
  margin-top: 40px;
  width: 83%;
}
.worke {
  margin-top: 35px;
  word-break: break-all;
  display: flex;
  flex-direction: column;
}
.Project {
  margin-top: 35px;
  word-break: break-all;
  display: flex;
  flex-direction: column;
}
.el-main {
  border: 1px solid #eee;
  border-bottom-color: transparent;
  padding: 0;
}
.aside {
  overflow: auto;
  box-sizing: border-box;
  flex-shrink: 0;
  position: fixed;
  top: 0;
}
.basic .head >>> .el-upload--picture-card {
  background-color: #fbfdff;
  border: none;
  border-radius: 6px;
  box-sizing: border-box;
  vertical-align: top;
}
.basic .head >>> .el-upload__tip {
  font-size: 12px;
  color: #606266;
  width: 100px;
  margin-left: 26px;
}
.avatar {
  width: 100%;
  height: 100%;
}
.infoBtn {
  margin-left: 10px;
}
.submitbutton {
  text-align: center;
  padding: 20px 0;
}
</style>