import http from '@/utils/http'

// 求职信息详情
export const candidate = (id,desCompanyId) => {
    return http.get('/reeresume/getcandidatedetail?id='+id+'&desCompanyId='+desCompanyId)
}

// 查询求职者列表
export const datagridCandidate = (params) =>{
    return http.post('/reeresume/getcandidatelist', params)
}