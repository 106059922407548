import http from '@/utils/http'

// 新增工作经历
export const job = (params) => {
    return http.post('/reeresume/addorupdatejobexperience', params)
}

// 删除工作经历

export const jobDelete = (id) => {
    return http.post('/reeresume/deljobexperience?id=' + id)
}

// 新增教育经验

export const education = (params) => {
    return http.post('/reeresume/addorupdateeducationexperience', params)
}

// 删除教育经验

export const educationDelete = (id) => {
    return http.post('/reeresume/deleducationexperience?id=' + id)
}

// 新增项目经验

export const projectAdd = (params) => {
    return http.post('/reeresume/addorupdateprojectexperience', params)
}

// 删除项目经验

export const projectDelete = (id) => {
    return http.post('/reeresume/delprojectexperience?id=' + id)
}

// 新增家庭社会成员关系
export const familyMember = (params) => {
    return http.post('/reeresume/addorupdaterelationexperience', params)
}

// 删除家庭社会成员关系

export const familyMemberDelete = (id) => {
    return http.post('/reeresume/delrelationexperience?id=' + id)
}


// 新增奖励

export const addorupdaterewardexperience = (params) => {
    return http.post('/reeresume/addorupdaterewardexperience', params)
}

// 删除教育经验

export const delrewardexperience = (id) => {
    return http.post('/reeresume/delrewardexperience?id=' + id)
}